
import Log from "./pages/Log";
import './App.css';

function App() {
    return (
        <div className="App-body">
            <Log />
        </div>
    );
}

export default App;
